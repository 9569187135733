import {
  createStore
} from 'redux'

const token = window.localStorage.getItem('token') || '';
// console.log(token)
const initialState = {
  sidebarShow: 'responsive',
  clickCount: 0,
  token: token,

  // 页面数据
  home:{
    subscription:null,
    technicalList:null
  },
  user:{
    userinfo:null,
    fmList:null,
    rules:null
  },
  develop:{
    fmList:null,
    fractionlist:null
  },
  ecology:{
    ryList:null,
    ryTotal:0,
    friendList:null,
    friendTotal:0,
    address:null
  },

  carouselImgs:{
    banner1:null,
    banner2:null,
    banner3:null,
    banner4:null,
  },
  ui: {
    width: ''
  }
}

const changeState = (state, {
  type,
  ...rest
}) => {
  if (!state) state = initialState;
  if (type === 'set') {
    return {
      ...state, ...rest
    };
  }
  else {
    return state
  }
}

const store = createStore(changeState)
export default store
