import './scss/style.scss';
import PropTypes from 'prop-types';
import React, { Component } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import withWidth from '@material-ui/core/withWidth';
import { connect } from 'react-redux';

const loading = (
  <div className="text-center" style={{marginTop:'40px'}}>
    <CircularProgress color="secondary" />
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// pages
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

class App extends Component {
  handleResize() {
    // 响应式宽度
    this.props.set({ type: 'set', ui: { width: this.props.width } });
  }
  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize.bind(this));
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize.bind(this));
  }

  render() {
    return (
      <div>
        <HashRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
              <Route path="/" name="Home" render={props => <TheLayout {...props} />} />
            </Switch>
          </React.Suspense>
        </HashRouter>
      </div>
    )
  }
}
App.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};
const mapStates = (state) => {
  return { ui: state.ui };
}
const mapActions = (dispatch) => {
  return {
    set: (action) => {
      dispatch(action)
    }
  };
}
export default connect(mapStates, mapActions)(withWidth()(App));
